import { useReactPWAInstall } from 'react-pwa-install'


export default function InstallApp({firstTime}){
    const { pwaInstall, supported, isInstalled } = useReactPWAInstall();

    if (firstTime && !isInstalled() && supported())  {
        pwaInstall({
            title: "Instalar Olivia",
            // // logo: myLogo,
            features: (
                <ul>
                    <li>Accede de forma rápida</li>
                    <li>Recibe notificaciones</li>
                </ul>
            ),
            description: "Asistente virtual de Seguros Crecer. ",
        })
            .then(() => alert("Se ha instalado la app"))
            .catch(() => alert("Puedes installar la app en otro momento"))

    }
    return (
        <div></div>
    )

}