import * as React from 'react'
import Joyride from 'react-joyride';
import Box from '@mui/material/Box'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { Menu, Home, Share, LoginSharp, Person2Sharp, QuestionMarkOutlined, Update, InstallDesktopSharp } from '@mui/icons-material/'
import { useReactPWAInstall } from 'react-pwa-install'

// type Anchor = 'top' | 'left' | 'bottom' | 'right'

export default function NavMenu({ goHome, login, logout, isConversational, share, selectPage, token, trial }) {
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    })

    const steps = [
        {
            disableBeacon: true,
            target: '.nav-menu',
            content: 'Ingresa a Lisa 2.0, descubre sus nuevas funcionalidades y capacidad conversacional',
        }
    ]

    const { pwaInstall, supported, isInstalled } = useReactPWAInstall();

    const handleInstallClick = () => {
        pwaInstall({
            title: "Instalar Olivia",
            // // logo: myLogo,
            features: (
                <ul>
                    <li>Accede de forma rápida</li>
                    <li>Recibe notificaciones</li>
                </ul>
            ),
            description: "Asistente virtual de afp crecer. ",
        })
            .then(() => alert("Se ha instalado la app"))
            .catch(() => alert("Puedes installar la app en otro momento"));
    };


    const navList = [
        isConversational ? { name: 'Mi cuenta', action: () => selectPage('account'), icon: <Person2Sharp /> } : '',
        !isConversational ? { name: 'Inicio', action: () => goHome(), icon: <Home /> } :
            { name: 'Inicio', action: () => selectPage(null), icon: <Home /> },
        !isConversational ? { name: 'Ir a Lisa Beta 2.0', action: () => login(), icon: <LoginSharp /> } :
            { name: 'Cerrar sesion', action: () => logout(), icon: <LoginSharp /> },
        { name: 'Compartir', action: (e) => share(e.target), icon: <Share /> },
    ]

    const toggleDrawer =
        (anchor, open) =>
            (event) => {
                if (
                    event &&
                    event.type === 'keydown' &&
                    ((event).key === 'Tab' ||
                        (event).key === 'Shift')
                ) {
                    return
                }

                setState({ ...state, [anchor]: open })
            }

    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List>
                {navList.map((item, index) => (
                    <ListItem key={item.name} disablePadding>
                        <ListItemButton onClick={item.action}>
                            <ListItemIcon>
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText primary={item.name} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            <Divider />
            {isConversational ? <List>
                {[{ name: 'Preguntas frecuentes', action: () => selectPage('faqs'), icon: <QuestionMarkOutlined /> },
                { name: 'Actualizaciones', action: (e) => selectPage('updates'), icon: <Update /> },
                { name: 'Instalar', action: () => handleInstallClick(), icon: <InstallDesktopSharp /> }].map((item, index) => (
                    <ListItem key={item.name} disablePadding>
                        <ListItemButton onClick={item.action}>
                            <ListItemIcon >
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText primary={item.name} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List> : ''}
        </Box>
    )

    return (
        <div style={{
            position: 'fixed',
            zIndex: 10000,
        }}>
            <React.Fragment key={'nav'}>
                {token ? '' : <Joyride steps={steps} style={{ marginLeft: '50px' }} />}
                <Menu onClick={toggleDrawer('left', true)} className='nav-menu' style={{
                    width: '50px',
                    height: '50px',
                    background: 'rgba(0, 0, 0, 0.28)',
                    color: 'white',
                    borderRadius: '50%',
                    margin: '10px 10px',
                    padding: '5px'

                }} />

                <SwipeableDrawer
                    anchor={'left'}
                    open={state['left']}
                    onClose={toggleDrawer('left', false)}
                    onOpen={toggleDrawer('left', true)}
                    style={{
                        zIndex: 30000,
                    }}
                >
                    {list('left')}
                </SwipeableDrawer>
            </React.Fragment>

        </div >
    )
}
