import React, { useEffect, useState, useCallback } from 'react'
import '../css/speechRecognition.css'
import axios from 'axios'
import { Backdrop } from '@mui/material'
import Room from './Room'
import useConfig from '../hooks/useConfig'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Replay from '@mui/icons-material/ReplayOutlined'
import Play from '@mui/icons-material/PlayArrowOutlined'
import Pause from '@mui/icons-material/PauseOutlined'
import Lottie from 'react-lottie'
import MessageInterface from './messageInterface'
import animationData from '../media/icons/loading.json'
import '../css/voiceInterface.css'
import Logo from '../media/icons/logo-blanco.png'
import Chip from '@mui/material/Chip'
import LoadingMessages from './loadingMessages'


const ErrorSistema = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-consulta/Error_en_el_sistema.mp4';

// const accountStateAudio = ''
const sdk = require("microsoft-cognitiveservices-speech-sdk")
const speechConfig = sdk.SpeechConfig.fromSubscription("adbb4d963fdd48ab95c14053002cfa28", "eastus")
speechConfig.speechRecognitionLanguage = "es-MX"
let audioConfig = sdk.AudioConfig.fromDefaultMicrophoneInput()

function Dictaphone({ videoIndex, listeningOn, stopSpeechListening, id, auth_token, sessionId, close, sound, setSound, trialCount, conversational }) {

    const [joined, setJoined] = useState(false)
    const [playing, setPlaying] = useState(false)
    const [accountStateFlow, setAccountStateFlow] = useState(false)
    const [cedula, setCedula] = useState()
    const [image, setImage] = useState()
    const [conversation, setConversation] = useState([])
    const [voiceMessage, setVoiceMessage] = useState(false)
    const [loading, setLoading] = useState(false)
    const [answers, setAnswers] = useState()
    const [IssuedId, setIssuedId] = useState()

    const {
        isLoadingCall,
        token,
        getToken,
        // roomName,
    } = useConfig()


    // eslint-disable-next-line no-unreachable
    const textToSpeech = useCallback(async (text) => {
        var content = {
            text: text,
        }
        const BASE_URL = process.env.REACT_APP_AI_API_URL
        await axios.post(BASE_URL + '/api/v1/text-to-speech/', content)
            .then(function (response) {
                setPlaying(true)
                setLoading(false)
                var data = response.data
                if (data) {
                    reproduceAudio(data.link)
                }
            })
            .catch(function (error) {
                //sistem error
                setLoading(false)
            })
    }, [reproduceAudio, setPlaying, setLoading])

    //eslint-disable-next-line react-hooks/exhaustive-deps
    const recognizer = new sdk.SpeechRecognizer(speechConfig, audioConfig)

    function replayResponse() {
        if (sound) {
            sound.stop()
            sound.play()
            setPlaying(true)
        }
    }


    useEffect(() => {
        recognizer.recognizing = (s, e) => {
        }

        recognizer.recognized = (s, e) => {
            if (e.result.reason === sdk.ResultReason.RecognizedSpeech) {
                // console.log(`RECOGNIZED: Text=${e.result.text}`)
                stopSpeechListening()
                if (accountStateFlow) {
                    setCedula(e.result.text)
                } else {
                    setLoading(true)
                    setVoiceMessage(false)
                    request(e.result.text)
                }

            }
            else if (e.result.reason === sdk.ResultReason.NoMatch) {
                // console.log("NOMATCH: Speech could not be recognized.")
            }
        }

        recognizer.canceled = (s, e) => {
            recognizer.stopContinuousRecognitionAsync()
        }

        recognizer.sessionStopped = (s, e) => {
            recognizer.stopContinuousRecognitionAsync()
        }


        const request = async (text) => {
            // if (trialCount()) {
            const requestInfo = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth_token
                },
                body: JSON.stringify({ 'prompt': conversation.concat([{ "role": "user", "content": text }]), "id": id, sessionId: sessionId, date: new Date().toLocaleString() })
            };
            const BASE_URL = process.env.REACT_APP_AI_API_URL
            fetch(BASE_URL + '/api/v1/conversation-prompt/', requestInfo)
                .then(res => res.json())
                .then(result => {

                    if (result.status) {

                    }
                    stopSpeechListening()
                    var lisaResponse = result.answer
                    var audioLink = result.audioUrl
                    var action = result.action
                    var imageUrl = result.imageUrl
                    if (action === 'video') {
                        getToken(sessionId)
                        setJoined(true)
                    }
                    if (action === 'estado de cuenta') {
                        setAccountStateFlow(true)
                        textToSpeech(lisaResponse)
                    }
                    setImage(imageUrl)
                    setConversation(conversation.concat([
                        { "role": "user", "content": text },
                        { "role": "assistant", "content": lisaResponse }]))

                    if (sound) { sound.stop() }
                    if (audioLink !== '') {
                        setSound(audioLink)
                        setPlaying(true)
                    }
                    setVoiceMessage(true)
                    setLoading(false)


                })
                .catch(function (error) {
                    videoIndex({ 'video': ErrorSistema });
                    setLoading(false)
                    return false
                })
            // }
        }


        if (listeningOn) {
            // checkPermissions()
            recognizer.recognizeOnceAsync()
        } else {
            recognizer.stopContinuousRecognitionAsync()
            stopSpeechListening()
        }
        if (listeningOn && sound) {
            sound.stop()
        }

    }, [auth_token, conversation, getToken, id, listeningOn, recognizer, sessionId, stopSpeechListening, textToSpeech, videoIndex, sound, setSound])


    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
            className: 'loading'
        }
    }

    if (loading || isLoadingCall) {

        return (
            <Backdrop
                sx={{
                    color: 'White', backgroundColor: '#ffffffa0',
                    zIndex: 15000, padding: '20px', backdropFilter: 'blur(5px)',
                    display: 'flex', flexDirection: 'column',
                    alignItems: 'center'
                }}
                open={loading || isLoadingCall}>
                <Lottie
                    options={defaultOptions}
                    height={200}
                    width={200}
                    isClickToPauseDisabled={true}
                />
                <LoadingMessages />


            </Backdrop>
        )
    } else if ((voiceMessage && !joined && conversational)) {

        let playControl = <Play className='pause-audio' onClick={() => playAudio()} />
        let pauseControl = <Pause className='pause-audio' onClick={() => pauseAudio()} />

        const closeLabel = <span>X</span >

        return (
            <React.Fragment>
                <Chip className="close-audio" label={closeLabel} variant="outlined" onClick={() => closeMessageInterface()} style={{ zIndex: 10000 }}> </Chip>
                <Backdrop
                    sx={{ color: 'black', backgroundColor: '#46555a', zIndex: 1000, padding: '20px', alignItems: 'flex-start' }}
                    open={voiceMessage} >

                    <img src={Logo} alt='AFP crecer' style={{ position: 'absolute', maxWidth: '150px', zIndex: 11, mixBlendMode: 'luminosity' }}></img>
                    <Box className='buttons-actions'>
                        <Replay className='replay-audio' onClick={() => replayResponse()} ></Replay>
                        {playing ? pauseControl : playControl}
                    </Box>
                    <Stack spacing={0} className='msg-container' justifyContent="center"
                        alignItems="center">
                        <MessageInterface
                            cedula={cedula}
                            setCedula={setCedula}
                            setConversation={setConversation}
                            conversation={conversation}
                            setLoading={setLoading}
                            loading={loading}
                            setAccountStateFlow={setAccountStateFlow}
                            textToSpeech={textToSpeech}
                            answers={answers}
                            setAnswers={setAnswers}
                            IssuedId={IssuedId}
                            setIssuedId={setIssuedId}
                            accountStateFlow={accountStateFlow}
                            imageUrl={image}
                            token={auth_token}
                            videoCall={startVideoCall} />
                        <p style={{ fontSize: '0.8em', color: '#ffffff7d', textAlign: 'center' }}>LISA puede mostrar información inexacta que no representa las opiniones de AFP Crecer.</p>
                    </Stack>
                    <Backdrop
                        sx={{ color: 'White', backgroundColor: '#ffffffa0', zIndex: 1500, padding: '20px', backdropFilter: 'blur(5px)' }}
                        open={listeningOn}>
                    </Backdrop>
                </Backdrop>

            </React.Fragment>
        )
    } else if (joined) {
        return (
            <div className="player-container" style={{ 'zIndex': 10000 }}>
                <Room
                    token={token}
                    roomName='Lisa-room'
                    onDisconnected={() => disconnected()}
                    errorHandle={() => errorHandle()}
                />
            </div >
        )
    }



    if (!joined) {
        return (
            <Backdrop
                sx={{ color: 'White', backgroundColor: '#ffffffa0', zIndex: 1500, padding: '20px', backdropFilter: 'blur(5px)' }}
                open={listeningOn}>
            </Backdrop>
        )
    }




    function disconnected() {
        setJoined(false)
        videoIndex({ 'video': '' })
        videoIndex({ 'video': 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-consulta/Intro_Afiliados_1.0.mp4' })
    }
    function errorHandle() {
        setJoined(false)
        setVoiceMessage(false)
        pauseAudio()
        videoIndex({ 'video': '' })
        videoIndex({ 'video': 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/GPT3/servicio%20al%20cliente%20no%20disponible_x264.mp4' })
    }

    function startVideoCall() {
        getToken(sessionId)
        setJoined(true)
    }

    function pauseAudio() {
        if (sound) {
            sound.pause()
            setPlaying(false)
        }
    }
    function playAudio() {
        if (sound) {
            sound.play()
            setPlaying(true)
        }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    function reproduceAudio(link) {
        setSound(link)
    }

    function closeMessageInterface() {
        setVoiceMessage(false)
        pauseAudio()
        close()
    }

}
export default Dictaphone