import { useState } from 'react'

const API_URL = process.env.REACT_APP_AI_API_URL + '/api/v1/prompt-feedback/'
const FB_INFO = {
  success: false,
}

/**
 * send the feedback for the prompted info 
 */
export default function PromptFeedback() {
  const [fbInfo, setFbInfo] = useState(FB_INFO)

  const promptFeedback = async (question, answer, feedback, additionalInfo, tagInfo, auth_token) => {
    const requestInfo = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': auth_token
      },
      body: JSON.stringify({
        question: question,
        answer: answer,
        additionalInfo: additionalInfo,
        feedback: feedback,
        tagInfo: tagInfo
      })
    };
    setFbInfo({ ...FB_INFO, isLoading: true })
    await fetch(`${API_URL}`, requestInfo)
      .then(res => res.json())
      .then(result => {
        if (result.success) {
          setFbInfo({
            success: true,
          })
        }
      })
      .catch(error => {

        setFbInfo(c => ({ ...c, error }))
      })
  }
  return { ...fbInfo, promptFeedback }
}
