import '@fontsource/roboto/500.css';
import '../css/login.css';
import React, { useRef, useState } from 'react';
import { Stack } from '@mui/material';
import { Button } from '@mui/material';
import { Rating } from '@mui/material';
import { Backdrop } from '@mui/material';
import { Star } from '@mui/icons-material';
//import { send } from 'react-ga';
// import Logo from '../media/icons/Logo.png';
import ReactGa from 'react-ga4';



export function SatisfactionSurvey({ ended, back, hide }) {
    const [value, setValue] = useState();
    const [hover, setHover] = useState();
    var title = useRef();
    var buttonSend = useRef();
    var rating = useRef();
    var thanks = useRef();
    var label = useRef();
    //  var thanksAnimation = useRef();

    const labels = {
        1: 'Muy mala',
        2: 'Mala',
        3: 'Buena',
        4: 'Muy buena',
        5: 'Excelente',
    };

    function send(value) {
        ReactGa.event({
            category: "Evaluacion",
            action: "¿Cómo fue tu experiencia conmigo?",
            label: "¿Cómo fue tu experiencia conmigo?", // optional
            value: value, // optional, must be a number
        });
        title.current.setAttribute("hidden", true);
        rating.current.setAttribute("hidden", true);
        buttonSend.current.setAttribute("hidden", true);
        thanks.current.removeAttribute("hidden");
        label.current.setAttribute("hidden", true);
    }

    return (
        <React.Fragment>
            <Backdrop
                sx={{ color: 'white', backgroundColor: '#009d5ff2', zIndex: (theme) => theme.zIndex.drawer + 10000 }}
                open={ended} >
                {/* <div className="logo">
                    <img src={Logo} width='120px' height='50px' alt='logo' ></img>
                </div > */}

                <Stack className="stack">

                    <h3 ref={title} >¿Cómo fue tu experiencia conmigo?</h3>
                    <h2 ref={thanks} hidden>¡Muchas gracias!</h2>
                    <Rating
                        name="hover-feedback"
                        value={value}
                        precision={1}
                        ref={rating}
                        onChange={(event, newValue) => {
                            setValue(newValue);
                        }}
                        onChangeActive={(event, newHover) => {
                            setHover(newHover);
                        }}
                        emptyIcon={<Star style={{ opacity: 1, fontSize: "70px" }} />}
                        icon={<Star style={{ fontSize: "70px" }} />}
                    />

                    {
                        value !== null && (
                            <h3 ref={label} style={{ color: "white" }} >{labels[hover !== -1 ? hover : value]}</h3>
                        )
                    }

                    <Button ref={buttonSend} className="button-inquiry" onClick={() => send(value)} disabled={value ? false : true}> Enviar</Button>
                    <Button className="button-inquire-hide" onClick={() => back()}>Volver al inicio</Button>
                </Stack>
            </Backdrop>
        </React.Fragment>
    );


}



