import '@fontsource/roboto/500.css';
import '../css/accountState.css';
import '../css/optionsList.css';
import { useParams } from 'react-router-dom';
import React from 'react';
import VideoPlayer from './videoPlayer';
import PinInput from 'react-pin-input';
import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { SatisfactionSurvey } from './satisfactionSurvey';

const RequerirDatos = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-consulta/Estado_listo_Pre_Estado_Disclaimer_1.0.mp4';
const ErrorAutenticacion = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-consulta/4_digitos_no_coinciden.mp4';
const axios = require('axios').default;
function AccountState() {

    const { id } = useParams();
    const [video, setVideo] = useState("");
    const [url, setUrl] = useState();
    const [pin, setPin] = useState();
    const [ended, setEnded] = useState();
    const pinContainer = React.useRef();
    const button = React.useRef();
    const button2 = React.useRef();
    const button3 = React.useRef();


    useEffect(() => {
        if (id !== undefined && video !== ErrorAutenticacion && pin === undefined) {
            requestVideo();
        }
    });

    async function requestVideo() {
        axios.defaults.headers.common['Content-Type'] = 'application/json;charset=utf-8';
        axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
        await axios.post(process.env.REACT_APP_API_URL + '/api/video/', { Id: id })
            .then(function (response) {
                setUrl(response.data.download);
                setPin(response.data.uIssuedId.slice(-4))
                setVideo(RequerirDatos);
            })
            .catch(function (error) {

            });
    }


    function onCompletePin(value) {
        if (pin === value) {
            setVideo(url);
            pinContainer.current.setAttribute("hidden", true);
            button.current.removeAttribute("hidden");
            button3.current.removeAttribute("hidden");
            button2.current.setAttribute("hidden", true);
        } else {
            setVideo(RequerirDatos);
            setVideo(ErrorAutenticacion);
        }

    }

    function goHome(e) {
        window.location.href = ("/");
        setEnded(false);
    }
    function displayOptions(e) {
        // pinContainer.current.removeAttribute("hidden");
        // button2.current.removeAttribute("hidden");
    }

    function finish(e) {
        setEnded(true);
    }

    return (
        <React.Fragment>
            <SatisfactionSurvey ended={ended} back={() => goHome()} hide={() => goHome()} />
            <VideoPlayer className="videoPlayer1" url={video} key={video} displayOptions={() => displayOptions()} />
            <div className="main" ref={pinContainer}>
                <h1 className="title" >Digita los últimos 4 números de tu cédula</h1>
                <PinInput
                    className="pin-input"
                    length={4}
                    initialValue=""
                    onChange={(value, index) => { }}
                    type="numeric"
                    inputMode="number"
                    style={{ padding: '10px' }}
                    inputStyle={{ borderColor: 'white' }}
                    inputFocusStyle={{ borderColor: 'red' }}
                    onComplete={(value, index) => { onCompletePin(value) }}
                    autoSelect={true}
                    regexCriteria={/^[0-9]*$/}
                />
            </div>
            <div className="button-container">
                <Button variant="outlined" ref={button3} hidden className="buttons-state" onClick={() => goHome()} ><span className="inside-button"> <span className='num'> 1 </span ><span>Continuar</span></span></Button>
                <Button variant="outlined" ref={button} hidden className="buttons-state" onClick={() => finish()} ><span className="inside-button"> <span className='num'> 2 </span ><span>Finalizar</span></span></Button>
                <Button variant="outlined" ref={button2} className="buttons-state" onClick={() => goHome()} ><span className="inside-button"> <span className='num'> 1 </span ><span>Regresar</span></span></Button>
            </div>
        </React.Fragment>
    );

}

export default AccountState;