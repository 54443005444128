import { useEffect, useState } from "react"
import React from 'react'

export default function LoadingMessages() {

    const [message, setMessage] = useState()
    const [currentMessage, setcurrentMessage] = useState(0)
    const [animationClass, setAnimationClass] = useState()


    const Messages = [
        { "message": 'Estoy interpretando tu pregunta.' },
        { "message": 'Estoy revisando mi base de conocimientos.' },
        { "message": 'Ahora, estoy construyendo tu respuesta.' },
    ]

    useEffect(() => {

        setTimeout(() => {
            setMessage(Messages[currentMessage].message)
            setAnimationClass('fade-in')
            if (currentMessage === 2) {

            } else {
                setcurrentMessage(currentMessage + 1)
            }
            setTimeout(() => {
                setAnimationClass('fade-out')
            }, 9000)
        }, 10000)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentMessage])

    return (
        <React.Fragment>
            <p style={{ color: 'black', fontSize: '1.3em', marginTop: '20%', position: 'relative', textAlign: 'center' }} className={animationClass}>
                {message}
            </p>
        </React.Fragment>
    )

}