import axios from 'axios';

export default async function authenticateAttempt(IssuedId) {

    var content = { IssuedId: IssuedId }

    let config = {
        headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'Access-Control-Allow-Origin': '*'
            // 'API-KEY': 'foSdvGMVPfYZyt-mlxTyOyVCOtOgzZezJdrbodvTZ0s',
            // 'TOKEN': 'd2hU_zq0oeelmbcCRuZcJHmmxLUadETfQE8LlUfoMKA'
        }
    }
    await axios.post(process.env.REACT_APP_REACT_APP_API_URL + '/api/user/attempt', content, config)
        .then(function (response) {
            return true;
        })
        .catch(function (error) {
            return false;
        });

}