

export const shortTerms = <div>
    Ten en cuenta que Lisa Beta 2.0 es un experimento<br></br><br></br>

    Mientras utilizas Lisa Beta 2.0, no olvides que:<br></br><br></br>

    Lisa Beta 2.0 no siempre dará respuestas correctas.<br></br><br></br>

    Lisa Beta 2.0 podría proporcionar respuestas imprecisas o inadecuadas. Si tienes dudas, presiona el botón "Contactar con un agente humano" para confirmar las respuestas de Lisa Beta 2.0.<br></br><br></br>

    Lisa Beta 2.0 se perfeccionará con tus comentarios<br></br><br></br>

    Asegúrate de calificar las respuestas y reportar cualquier contenido ofensivo o inseguro.<br></br><br></br>

    Infórmate sobre las actualizaciones y mejoras de Lisa Beta 2.0<br></br><br></br>

    Elige recibir noticias por correo electrónico sobre Lisa Beta 2.0. AFP CRECER procesará tus datos personales para enviar dichos correos, conforme a la Política de Privacidad de AFP CRECER.</div>


export const terms = <div>
    <h4><b>Términos y Privacidad</b></h4>

    <p>Tus datos y LISA Beta 2.0</p><br></br>

    <p>Este aviso y nuestra Política de Privacidad describen cómo LISA Beta 2.0 maneja tus datos. Por favor, léelos cuidadosamente.</p>

    <p>AFP CRECER recoge tus conversaciones con LISA Beta 2.0, información relacionada con el uso del producto, la zona general en la que te encuentras y tus comentarios. AFP CRECER utiliza estos datos, de acuerdo con nuestra Política de Privacidad, para proporcionar, mejorar y desarrollar productos y servicios de AFP CRECER y tecnologías de aprendizaje automático.</p>



    <p>Por defecto, AFP CRECER almacena esta información con tu cuenta de AFP CRECER durante hasta 18 meses. Para ayudar con la calidad y mejorar nuestros productos, revisores humanos leen, anotan y procesan tus conversaciones con LISA Beta 2.0. Tomamos medidas para proteger tu privacidad como parte de este proceso. Esto incluye desconectar tus conversaciones con LISA Beta 2.0 de tu cuenta de AFP CRECER antes de que los revisores las vean o las anoten. Por favor, no incluyas información que pueda ser utilizada para identificarte a ti o a otros en tus conversaciones con LISA Beta 2.0.</p>



    <p> Si quieres usar LISA Beta 2.0 sin guardar tus conversaciones en tu cuenta de AFP CRECER, puedes pausar el guardado de tu actividad con LISA Beta 2.0. Puedes revisar tus mensajes o eliminar tus conversaciones con LISA Beta 2.0 de tu cuenta en afpcrecer.com.do/lisa/. Las conversaciones con LISA Beta 2.0 que han sido revisadas o anotadas por revisores humanos no se borran cuando borras tu actividad con LISA Beta 2.0 porque se guardan por separado y no están conectadas a tu cuenta de AFP CRECER. En su lugar, se conservan hasta por tres años.</p>



    <p>Consulta las preguntas frecuentes de LISA Beta 2.0 para aprender más sobre cómo funciona LISA Beta 2.0 y cómo AFP CRECER utiliza tus datos de LISA Beta 2.0.</p>



    <p>Cosas que debes saber</p>

    <p>LISA Beta 2.0 utiliza tus conversaciones pasadas y la zona general en la que te encuentras para proporcionarte su mejor respuesta.</p>

    <p>LISA Beta 2.0 es una tecnología experimental y a veces puede dar información inexacta o inapropiada que no representa las opiniones de AFP CRECER.</p>

    <p>No te bases en las respuestas de LISA Beta 2.0 para asesoramiento médico, legal, financiero u otro tipo de asesoramiento profesional.</p>

    <p>No incluyas información confidencial o sensible en tus conversaciones con LISA Beta 2.0.</p>

    <p>Tus comentarios ayudarán a mejorar a LISA Beta 2.0.</p>

    <p><b>Términos de Servicio</b></p>

    <p>Aceptas que tu uso de LISA Beta 2.0 está sujeto a los Términos de Servicio del sitio web de AFP CRECER y a los Términos de Servicio Adicionales de IA Generativa. </p>
    <br></br><br></br>
    <h5><b>TÉRMINOS DE SERVICIO ADICIONALES DE IA GENERATIVA</b></h5><br></br>

    <p>Última modificación: 14 de marzo de 2023</p >



    <p> Para usar los servicios del LISA Beta 2.0 que hacen referencia a estos términos (los "Servicios"), debes aceptar (1) los Términos de Servicio del website de AFP CRECER, y (2) estos Términos de Servicio Adicionales de IA Generativa.</p>



    <p>Por favor, lee estos documentos cuidadosamente. Juntos, estos documentos son conocidos como los "Términos". Establecen qué puedes esperar de nosotros al usar nuestros servicios y qué esperamos de ti.</p>



    <p>También te animamos a leer nuestra Política de Privacidad(y cualquier aviso de privacidad presentado en conexión con un Servicio) para entender mejor qué información recopilamos y cómo puedes actualizar, gestionar, exportar y eliminar tu información.</p >



    <p>Requisitos de edad</p>

    <p>Debes tener 18 años de edad o más para usar los Servicios de LISA Beta 2.0. </p>



    <p>Restricciones de uso</p>

    <p>No puedes usar los Servicios de LISA Beta 2.0 para desarrollar modelos de aprendizaje automático o tecnología relacionada.</p>



    <p>Debes cumplir con nuestra Política de Uso Prohibido, que proporciona detalles adicionales sobre la conducta apropiada al usar los Servicios.</p>



    <p>Los Servicios de LISA Beta 2.0 incluyen características de seguridad para bloquear contenido perjudicial, como contenido que viola nuestras Política de Uso del sitio Web de AFP Crecer. No debes intentar eludir estas medidas de protección o usar contenido que viola estos Términos.</p>



    <p>No deberás introducir ninguna información personal o sensible, incluyendo nombres, números de teléfono, direcciones, correos electrónicos, o fechas de nacimiento.</p>



    <p>Descargos de responsabilidad</p>

    <p>Los Servicios de LISA Beta 2.0 utilizan tecnología experimental y pueden a veces proporcionar contenido inexacto u ofensivo que no representa las opiniones de AFP CRECER.</p>



    <p> Usa tu criterio antes de confiar, publicar, o de otra manera usar contenido proporcionado por los Servicios de LISA Beta 2.0.</p>



    <p>No te bases en los Servicios de LISA Beta 2.0 para consejos médicos, legales, financieros u otro tipo de asesoramiento profesional. Cualquier contenido sobre estos temas se proporciona solo con fines informativos y no es un sustituto de la asesoría de un profesional cualificado. </p></div>