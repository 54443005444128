import '../css/videoPlayer.css'
import React, { Component } from 'react'
import { Player, ControlBar } from 'video-react'
import Backdrop from '@mui/material/Backdrop'
import PlayCircleIcon from '@mui/icons-material/PlayCircle'
import { CircularProgress } from '@mui/material'
import ReplayIcon from '@mui/icons-material/Replay'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight'
import InstallApp from './installApp'

export default class VideoPLayer extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {}
        this.replay = this.replay.bind(this);
        this.foward = this.foward.bind(this);
        this.play = this.play.bind(this);
        this.pause = this.pause.bind(this);
        this.handleValueChange = this.handleValueChange.bind(this);
        this.updatePlayerInfo = this.updatePlayerInfo.bind(this);
        this.handlePlayIcon = this.handlePlayIcon.bind(this);
        this.handleLoading = this.handleLoading.bind(this);
    }


    play() {
        this.player.play();
    }

    replay() {
        var time = this.player.getState().player.currentTime;
        this.player.replay(time - 0.2);
        this.play();
    }
    foward() {
        this.props.displayOptions();
    }

    pause() {
        this.player.pause();
    }
    componentDidMount() {
        this.setState({firstTime: true})
        // subscribe state change
        this.player.subscribeToStateChange(this.handleStateChange.bind(this));

    }

    componentDidUpdate(prevProps, prevState) {

        if (this.state.playerSource !== this.props.url) {
            this.setState({ playerSource: this.props.url });
            this.player.load();
        }

        if (this.props.pause || this.props.listeningOn) {
            this.player.pause();
        }

    }

    handleStateChange(state, prevState) {
        // copy player state to this component's state
        this.setState({
            player: state,
            paused: state.paused,
            ended: state.ended,
            loading: state.readyState,
        });

        var time = this.player.getState().player.currentTime;
        var duration = this.player.getState().player.duration;
        if ((duration - time) < 20) {
            this.props.displayOptions();
            let displayPopup = this.state.firstTime ? this.setState({ firstTime: false }) || this.setState({displayPopUp: true}): this.setState({displayPopUp: false});
        }
        if (state.ended === true) {
            this.props.ended(true);
        }
    }
    handleValueChange(e) {
        const { value } = e.target;
        this.setState({
            inputVideoUrl: value
        });
    }

    updatePlayerInfo() {
        const { inputVideoUrl } = this.state;
        this.setState({
            playerSource: inputVideoUrl
        });
    }

    handlePlayIcon() {
        if (!this.state.ended && this.state.paused && this.state.loading === 4) {
            return true
        } else {
            return false
        }

    }

    handleLoading() {
        if (this.state.loading !== 4) {
            return true
        } else {
            return false
        }
    }
    render() {
        let icon = <div><ReplayIcon className='replay' onClick={() => this.replay()} ></ReplayIcon> <KeyboardDoubleArrowRightIcon className='foward' onClick={() => this.foward()} ></KeyboardDoubleArrowRightIcon></div>
        if (this.props.showIcon) {
            icon = null
        }
        
        return (
            <div className="player-container">
                {icon}
                <div className="hover-layer"></div>
                <Player className="video" src={this.props.url} ref={player => { this.player = player; }} autoPlay={true} videoId="video-1" playsInline={true} >
                    <ControlBar autoHide={true} disableDefaultControls={true} />
                </Player>
                <Backdrop
                    sx={{ color: '#fff', zIndex: 500 }}
                    open={this.handlePlayIcon()}
                    onClick={this.play}
                >
                    <PlayCircleIcon sx={{ color: "white", fontSize: 80 }} />
                </Backdrop>
                <InstallApp firstTime={this.state.displayPopUp} />
                <Backdrop
                    sx={{ color: '#fff', zIndex: 1 }}
                    open={this.handleLoading()}
                    onClick={this.play}
                >
                    <CircularProgress color="inherit" />

                </Backdrop>
            </div>
        );
    }
}