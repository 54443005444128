import { useState } from 'react'

const API_URL = process.env.REACT_APP_AI_API_URL + `/api/v1/video_token/`
const INITIAL_STATE = {
  isLoadingCall: false,
  error: null,
  token: null,
  identity: null,
  roomName: null
}
/**
 * get token to connect to a room
 */
export default function useConfig() {
  const [config, setConfig] = useState(INITIAL_STATE)

  const getToken = async (session) => {
    setConfig({ ...INITIAL_STATE, isLoadingCall: true })
    const requestInfo = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ identity: session })
    };
    await fetch(`${API_URL}`, requestInfo)
      .then(res => res.json())
      .then(result => {
        if (result.token) {
          setConfig({
            isLoadingCall: false,
            token: result.token,
          })
        }
      })
      .catch(error => {

        setConfig(c => ({ ...c, isLoadingCall: false, error }))
      })
  }
  return { ...config, getToken }
}
