import React from 'react'
import AudioTrack from './AudioTrack'
import VideoTrack from './VideoTrack'
import { useTrack } from 'use-twilio-video'

function Participant({ participant }) {
  const { videoOn, audioOn, videoTrack, audioTrack } = useTrack({ participant })

  return (
    <div style={{ 'height': '100%' }} >
      <VideoTrack track={videoTrack} />
      <AudioTrack track={audioTrack} />
    </div>
  )
}

export default Participant
