let Intro = ''
const QuienesSomos = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-conaulta-seguros/1%20Quines%20somos_x264.mp4'
const VolverQuienesSomos = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-conaulta-seguros/7%20Volver%20-%20quines%20somos_x264.mp4'
const SeguroVehiculo = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-conaulta-seguros/8%20Seguro%20de%20vehculo_x264.mp4'
const OtrosProductos = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-conaulta-seguros/9%20Otros%20productos_x264.mp4'
const DondeEstamos = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-conaulta-seguros/14%20Dnde%20estamos%20_%20oficinas_x264.mp4'
const Coberturas = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-conaulta-seguros/22%20Coberturas_x264.mp4'
const Ventajas = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-conaulta-seguros/23%20Ventajas_x264.mp4'
const VolverSeguroVehiculo = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-conaulta-seguros/25%20Volver%20-%20Seguro%20de%20vehculo_x264.mp4'
const VolverOtrosProductos = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-conaulta-seguros/26%20Volver%20-%20Otros%20productos_x264.mp4'
const SantoDomingo = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-conaulta-seguros/62%20-%20Santo%20Domingo_x264.mp4'
const Santiago = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-conaulta-seguros/63%20-%20Santiago_x264.mp4'
const Bavaro = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-conaulta-seguros/64%20-%20Bvaro_x264.mp4'
const Volver_65 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-conaulta-seguros/65%20-%20Volver_x264.mp4'
const Quees = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-conaulta-seguros/66%20-%20Qu%20es_x264.mp4'
const PorqueEsNecesario = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-conaulta-seguros/67%20-%20porqu%20es%20necesario__x264.mp4'
const ComoPagar = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-conaulta-seguros/68%20-%20Cmo%20pagar_x264.mp4'
const QueHacerEnCasoDeAccidente = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-conaulta-seguros/69%20-%20Qu%20hacer%20en%20caso%20de%20accidente__x264.mp4'
const Volver_70 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-conaulta-seguros/70-%20volver_x264.mp4'
const Volver_QueEs = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-conaulta-seguros/71%20Volver%20qu%20es__x264.mp4'
const Volver_PorqueEsNecesario = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-conaulta-seguros/72%20Volver%20porqu%20es%20necesario__x264.mp4'
const Volver_CoverturaVentajas = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-conaulta-seguros/73%20Volver%20coberturas%20y%20ventajas_x264.mp4'
const Volver_ComoPagar = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-conaulta-seguros/74%20Volver%20%20Cmo%20pagar_x264.mp4'
const InformacionDeLaEmpresa = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-conaulta-seguros/90%20Informacin%20de%20la%20empresa_x264.mp4'
const Pasos = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-conaulta-seguros/76%20Pasos_x264.mp4'
const Volver_75 ='https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-conaulta-seguros/75%20Volver_x264.mp4'
const VolverAccidente = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-conaulta-seguros/77%20Accidente%20de%20vehculo%20-%20Volver_x264.mp4'
const ConsejosSeguridad = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-conaulta-seguros/Olivia%20-%20Intro_x264.mp4'
const ProductosPrevisionales = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-conaulta-seguros/99%20-%20Productos%20de%20previsionales%20Volver_x264.mp4'
const BancaSeguros = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-conaulta-seguros/102%20-%20Productos%20de%20bancaseguros%20Volver_x264.mp4' 
const VolverProductosPrevisionales = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-conaulta-seguros/99%20-%20Productos%20de%20previsionales%20Volver_x264.mp4'
const VolverBancaSeguros = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-conaulta-seguros/102%20-%20Productos%20de%20bancaseguros%20Volver_x264.mp4'
const SegurosPersonas = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-conaulta-seguros/Seguros%20de%20personas_x264.mp4'
const RiesgosLaborales = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-conaulta-seguros/101%20-%20Riesgos%20generales_x264.mp4'
const VolverSegurosPersonas = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-conaulta-seguros/107%20-%20Seguros%20de%20personas%20Volver_x264.mp4'
const VolverRiesgosLaborales = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-conaulta-seguros/108%20-%20Riesgos%20generales%20Volver_x264.mp4'

var date = new Date().getHours()

// good morning video
if (date >= 5) {
    Intro = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-conaulta-seguros/BUENOS%20DAS_x264.mp4' 
}
// good afternoon video
if (date >= 12) { Intro = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-conaulta-seguros/BUENAS%20TARDES_x264.mp4' }
// good night video
if (date > 18 || date < 5) { Intro =  'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-conaulta-seguros/BUENAS%20NOCHES%20(1)_x264.mp4'}


export const nodes = [
    {
        id: 0,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                nextNodeId: 1,
                videoUrl: InformacionDeLaEmpresa,
                text: 'Información de la empresa',
            },
            {
                type: 'node',
                videoUrl: SeguroVehiculo,
                text: 'Seguro de vehículo',
                nextNodeId: 2,
            },
            // {
            //     type: 'node',
            //     videoUrl: ConsejosSeguridad,
            //     text: 'Consejos de seguridad',
            //     nextNodeId: 15,
            // },
            {
                type: 'node',
                videoUrl: OtrosProductos,
                text: 'Otros productos',
                nextNodeId: 3,
            }  
        ],
        video: Intro,
    },
    {
        id: 1,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: QuienesSomos,
                text: '¿Quiénes somos?',
                nextNodeId: 4,
            },
            {
                type: 'node',
                videoUrl: DondeEstamos,
                text: '¿Dónde estamos?',
                nextNodeId: 5,
            },
            {
                type: 'node',
                videoUrl: VolverQuienesSomos,
                text: 'Volver',
                nextNodeId: 0,
            }
        ],
    },
    {
        id: 2,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: ComoPagar,
                text: '¿Cómo pagar?',
                nextNodeId: 9,
           },
           {
            type: 'node',
            videoUrl: QueHacerEnCasoDeAccidente,
            text: '¿Qué hacer en caso de accidente?',
            nextNodeId: 10,
            },
            {
                type: 'node',
                videoUrl: Quees,
                text: '¿Qué es el seguro de vehículo?',
                nextNodeId: 6,
            },
            {
                type: 'node',
                videoUrl: Coberturas,
                text: 'Coberturas',
                nextNodeId: 8,
            },
            {
                type: 'node',
                videoUrl: Ventajas,
                text: 'Ventajas',
                nextNodeId: 8,
            },
            {
                type: 'node',
                videoUrl: PorqueEsNecesario,
                text: '¿Por qué es necesario el seguro de vehículo?',
                nextNodeId: 7,
            },
            {
                type: 'node',
                videoUrl: VolverSeguroVehiculo,
                text: 'Volver',
                nextNodeId: 0,
            }
        ],
    },
    {
        id: 3,
        title: '',
        inputs: [],
        buttons: [
            // {
            //     type: 'node',
            //     videoUrl: ProductosPrevisionales,
            //     text: 'Productos previsionales',
            //     nextNodeId: 20,
            // },
            // {
            //     type: 'node',
            //     videoUrl: BancaSeguros,
            //     text: 'Productos de bancaseguros',
            //     nextNodeId: 21,
            // },  
            {
                type: 'node',
                videoUrl: VolverOtrosProductos,
                text: 'Volver',
                nextNodeId: 0,
            }
        ],
    },
    {
        id: 4,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: VolverQuienesSomos,
                text: 'Volver',
                nextNodeId: 1,
            }
        ],
    },
    {
        id: 5,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: SantoDomingo,
                text: 'Santo Domingo',
                nextNodeId: 11,
            },
            {
                type: 'node',
                videoUrl: Santiago,
                text: 'Santiago',
                nextNodeId: 12,
            },
            {
                type: 'node',
                videoUrl: Bavaro,
                text: 'Bávaro',
                nextNodeId: 13,
           },
            {
                type: 'node',
                videoUrl: Volver_70,
                text: 'Volver',
                nextNodeId: 1,
            }
        ],
    },
    {
        id: 6,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: Volver_QueEs,
                text: 'Volver',
                nextNodeId: 2,
            }
        ],
    },
    {
        id: 7,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: Volver_PorqueEsNecesario,
                text: 'Volver',
                nextNodeId: 2,
            }
        ],
    },
    {
        id: 8,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: Volver_CoverturaVentajas,
                text: 'Volver',
                nextNodeId: 2,
            }
        ],
    },
    {
        id: 9,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: Pasos,
                text: 'Pasos',
                nextNodeId: 14,
            },
            {
                type: 'node',
                videoUrl: Volver_ComoPagar,
                text: 'Volver',
                nextNodeId: 2,
            }
        ],
    },
    {
        id: 10,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'link',
                url: 'https://api.whatsapp.com/send/?phone=18292224040&text=Quiero+reportar+un+da%C3%B1o+a+mi+propiedad+o+veh%C3%ADculo&type=phone_number&app_absent=0',
                text: 'Reportar a Seguros Crecer',
            },
            {
                type: 'link',
                url: 'https://api.whatsapp.com/send/?phone=18099205000&text=Quiero+reportar+un+da%C3%B1o+a+mi+propiedad+o+veh%C3%ADculo.&type=phone_number&app_absent=0',
                text: 'Reportar a Creciendo Seguros',
            },
            {
                type: 'node',
                videoUrl: VolverAccidente,
                text: 'Volver',
                nextNodeId: 2,
            }
        ],
    },
    {
        id: 11,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'link',
                url: 'https://www.google.com/maps/place/Banesco+Seguros/@18.4775174,-69.9366718,17z/data=!3m1!4b1!4m6!3m5!1s0x8eaf89e8d1c05b2d:0x484cc534416fe203!8m2!3d18.4775174!4d-69.9366718!16s%2Fg%2F11c250ynjb?hl=es-DO&entry=ttu',
                text: 'Ver en el mapa',
                nextNodeId: 5,
            },
            {
                type: 'node',
                videoUrl: Volver_65,
                text: 'Volver',
                nextNodeId: 5,
            }
        ],
    },
    {
        id: 12,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'link',
                url: 'https://www.google.com/maps/place/Banesco+Juan+Pablo+Duarte+(Santiago)/@19.458315,-70.6768077,17z/data=!3m1!4b1!4m6!3m5!1s0x8eb1c586c19a3151:0x61da21eea1c2855!8m2!3d19.458315!4d-70.6768077!16s%2Fg%2F1ptyyt_23?hl=es-DO&entry=ttu',
                text: 'Ver en el mapa',
                nextNodeId: 5,
            },
            {
                type: 'node',
                videoUrl: Volver_65,
                text: 'Volver',
                nextNodeId: 5,
            }
        ],
    },
    {
        id: 13,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'link',
                url: 'https://www.google.com/maps/place/C.+P.%C2%BA+San+Juan,+Punta+Cana+23000/@18.6400267,-68.3963063,17z/data=!3m1!4b1!4m6!3m5!1s0x8ea89331009e3d71:0x66dfe400c3a2249a!8m2!3d18.6400267!4d-68.3963063!16s%2Fg%2F11h54zlfk6?hl=es-DO&entry=ttu',
                text: 'Ver en el mapa',
                nextNodeId: 5,
            },
            {
                type: 'node',
                videoUrl: Volver_65,
                text: 'Volver',
                nextNodeId: 5,
            }
        ],
    },
    {
        id: 14,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: Volver_75,
                text: 'Volver',
                nextNodeId: 9,
            }
        ],
    },
    {
        id: 15,
        title: '¿Qué te gustaría saber?',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-conaulta-seguros/Recomendaciones%20generales_x264.mp4',
                text: '¿Cómo prepararme para la tormenta?',
                nextNodeId: 16,
            },
            {
                type: 'node',
                videoUrl: 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-conaulta-seguros/Cmo%20proteger%20mi%20vehculo__x264.mp4',
                text: '¿Cómo proteger mi vehículo?',
                nextNodeId: 17,
            },
            {
                type: 'node',
                videoUrl: 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-conaulta-seguros/Olivia%20-%20Cmo%20proteger%20mi%20propiedad%20inmobiliaria__x264.mp4',
                text: '¿Cómo proteger mi propiedad?',
                nextNodeId: 18,
            },
            {
                type: 'node',
                videoUrl: 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-conaulta-seguros/Olivia%20-%20Cmo%20reportar%20un%20dao__x264.mp4',
                text: '¿Cómo reportar un daño?',
                nextNodeId: 19,
            },
            {
                type: 'node',
                videoUrl: Intro,
                text: 'Ir al Inicio',
                nextNodeId: 0,
            }
        ],
    },
    {
        id: 16,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-conaulta-seguros/Recomendaciones%20Generales%20Volver_x264.mp4',
                text: 'Volver',
                nextNodeId: 15,
            }
        ],
    },
    {
        id: 17,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: SeguroVehiculo,
                text: 'Sobre el seguro de vehículo',
                nextNodeId: 2,
            },
            {
                type: 'node',
                videoUrl: 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-conaulta-seguros/Cmo%20proteger%20mi%20vehculo%20Volver_x264.mp4',
                text: 'Volver',
                nextNodeId: 15,
            }
        ],
    },
    {
        id: 18,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-conaulta-seguros/Cmo%20proteger%20mi%20propiedad%20inmobiliaria%20Volver_x264.mp4',
                text: 'Volver',
                nextNodeId: 15,
            }
        ],
    },   
    {
        id: 19,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'link',
                url: 'https://api.whatsapp.com/send/?phone=18292224040&text=Quiero+reportar+un+da%C3%B1o+a+mi+propiedad+o+veh%C3%ADculo&type=phone_number&app_absent=0',
                text: 'Reportar a Seguros Crecer',
                nextNodeId: 5,
            },
            {
                type: 'link',
                url: 'https://api.whatsapp.com/send/?phone=18099205000&text=Quiero+reportar+un+da%C3%B1o+a+mi+propiedad+o+veh%C3%ADculo.&type=phone_number&app_absent=0',
                text: 'Reportar a Creciendo Seguros',
                nextNodeId: 5,
            },
            {
                type: 'node',
                videoUrl: 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-conaulta-seguros/Cmo%20reportar%20un%20dao%20Volver_x264.mp4',
                text: 'Volver',
                nextNodeId: 15,
            }
        ],
    },
    {
        id: 20,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: VolverProductosPrevisionales,
                text: 'Volver',
                nextNodeId: 3,
            }]
    },
    {
        id: 21,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: SegurosPersonas,
                text: 'Seguros de personas',
                nextNodeId: 22,
            },
            {
                type: 'node',
                videoUrl: RiesgosLaborales,
                text: 'Riesgos laborales',
                nextNodeId: 23,
            },
            {
                type: 'node',
                videoUrl: VolverBancaSeguros,
                text: 'Volver',
                nextNodeId: 3,
            }]
    },
    {
        id: 22,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: VolverSegurosPersonas,
                text: 'Volver',
                nextNodeId: 21,
            }]
    },
    {
        id: 23,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                videoUrl: VolverRiesgosLaborales,
                text: 'Volver',
                nextNodeId: 21,
            }]
    }
];