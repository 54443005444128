
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Button from '@mui/material/Button'
import '../css/optionsList.css'
import Stack from '@mui/material/Stack'
import '../css/accountStateFlow.css'

export function AccountStateFlow({ cedula, setConversation, conversation, setLoading, setAccountStateFlow, setCedula, loading, textToSpeech, answers, setAnswers, IssuedId, setIssuedId }) {
    const [buttons, setButtons] = useState()

    let cleanedCedula

    if (cedula) {
        var match = cedula.match(/\d+/g)
        if (match) {
            cleanedCedula = match.join('')
        }

    }

    // eslint-disable-next-line no-unreachable
    const autentication = async (cedula) => {
        var content = {
            IssuedId: cleanedCedula
        }
        setIssuedId(cleanedCedula)
        await axios.post(process.env.REACT_APP_API_URL + '/api/user/authenticate/', content)
            .then(function (response) {

                var buttons = []
                if (response.data.successed === true) {
                    textToSpeech("¿Cuál es tu fecha de nacimiento?, selecciona una de las siguientes opciones")
                    var secQuestions = response.data.value;
                    let x = 1
                    secQuestions[1].respuestas.forEach(respuesta => {
                        var button = {
                            index: x,
                            text: respuesta.valor,
                            correct: respuesta.esCorrecta,
                        }
                        buttons.push(button);
                        setConversation(conversation.concat([
                            { "role": "assistant", "content": '¿Cuál es tu fecha de nacimiento?' },
                            { "role": "assistant", "content": 'selecciona una de las siguientes opciones' }]))
                        x++
                    });
                    setAnswers(buttons)
                    setLoading(false)
                }
                else {
                    //node index for not exist user
                    setLoading(false)
                    response = '¿Tu número de identificación es "' + cleanedCedula + '"? Si es así, ' +
                        'es probable que no estés registrado en nuestro sistema o que tu registro esté ' +
                        'en proceso.Si este no es tu número de identificación, por favor dime cuál es para que pueda asistirte mejor.'
                    textToSpeech(response)
                    setConversation(conversation.concat([
                        { "role": "assistant", "content": 'No encontre tu numero de cédula en nuestra base de datos' },
                        { "role": "assistant", "content": response }]))

                }

            })
            .catch(function (error) {
                setLoading(false)
            });

    }

    // eslint-disable-next-line no-unreachable
    const getUserInfo = async (cedula) => {
        var content = {
            IssuedId: IssuedId,

        }
        await axios.post(process.env.REACT_APP_API_URL + '/api/getuserinfo/', content)
            .then(function (response) {
                setLoading(false)
                var userInfo = response.data;
                var script = " Al " + userInfo.date + ", tienes un balance de " + userInfo.cap_account + " pesos con " + userInfo.cap_account_cent +
                    " centavos, en tu cuenta de capitalizacion individual. En el corte anterior, el " + userInfo.last_date + ", tu balance era de " + userInfo.old_cap_account +
                    " pesos con " + userInfo.old_cap_account_cent + " centavos." + "Esto significa, que en " + userInfo.months + ", has sumado " + userInfo.profit + " pesos con " +
                    userInfo.profit_cent + " centavos a tu cuenta, con lo que tu fondo ha crecido, un " + userInfo.percent + "%." + "Como puedes ver, han sido meses muy productivos" +
                    " para tí.Este resultado lo hemos logrado, gracias a las gestiones de inversión que todos los días hacemos por ti. Gracias " + userInfo.name + ", por confiar en AFP Crecer."
                textToSpeech("Hola " + userInfo.name + "!" + script)
                setConversation(conversation.concat([
                    { "role": "assistant", "content": "Hola " + userInfo.name + "!" },
                    { "role": "assistant", "content": script }]))

                setAccountStateFlow(false)

            })
            .catch(function (error) {
                //sistem error
                setLoading(false)
            });

    }

    useEffect(() => {


        if (!answers && !loading && cedula) {
            setLoading(true)
            autentication(cleanedCedula)
            setCedula(false)
        }

        if (answers) {
            let buttons = []
            answers.forEach(button => {
                const text = <span className="inside-button"> <span className='num'> {button.index} </span ><span>{button.text}</span></span>
                buttons.push(<Button variant="outlined" key={button.text} className="buttons" node-id={button.nextNodeId} video-url={button.videoUrl} isCorrect={button.correct.toString()} onClick={() => validateAnwser(button.index)} button-index={button.index} > {text}</Button>)
            })
            setButtons(buttons)
        }

    }, [answers, cedula])

    return (
        <div className="list-dates"  >
            <div className="buttons-list controls-cta">
                <Stack>
                    {buttons}
                </Stack>
            </div>
        </div>
    )

    function validateAnwser(buttonIndex) {
        if (answers[buttonIndex - 1].correct) {
            setLoading(true)
            getUserInfo(cleanedCedula)
        } else {
            setConversation(conversation.concat([
                { "role": "assistant", "content": 'No hemos podido validar tu identidad' },
                { "role": "assistant", "content": 'por favor intenta de nuevo mas tarde.' }]))
            textToSpeech('No hemos podido validar tu identidad, por favor intenta de nuevo mas tarde.')
            setAccountStateFlow(false)
        }


    }


}

