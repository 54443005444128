import '@fontsource/roboto/500.css'
import React, { Component } from 'react'
import Button from '@mui/material/Button'
import '../css/optionsList.css'
import { nodes } from './nodes'
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'
import Alert from '@mui/material/Alert'
import { CircularProgress } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import ReactGa from 'react-ga4';
import ReferEvent from './referEvent';
import authenticateAttempt from './authenticateAttempt'
import { WhatsApp } from '@mui/icons-material'

import {
    WhatsappShareButton,
} from "react-share";

const confirmacion = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-consulta/007%20v.%202.3%20-%20Datos%20confirmados_x264.mp4';
const RespuestaNoConcuerda = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-consulta/Respuesta%20incorrecta%20a%20preguntas%20de%20seguridad%20-%20Ver.%202.3_x264.mp4';
const TienesUnEstado = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-consulta/Tienes_resumen_reciente_Ver.0.mp4';
const IntroAfiliado = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-consulta/Intro_Afiliados_1.0.mp4';
const Noafiliado = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-consulta/No_de_cdula_no_aparece_en_nuestro_sistema_Ver.0.mp4';
const NumeroTelefono = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-consulta/Cual_telfono_reconoces.mp4';
const UltimoEmpleador = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-consulta/Cual_fue_tu_empleador.mp4';
const ErrorSistema = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-consulta/Error_en_el_sistema.mp4';
const intentosExcedidos = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-consulta/Consulta%20no%20disponible%20por%20haber%20excedido%20el%20nmero%20mximo%20respuestas%20fallidas%20%20-%20Ver.%202.3%20%281%29_x264.mp4'

const axios = require('axios').default;

class ButtonsList extends Component {
    constructor() {
        super();
        this.state = {
            node: nodes[0],
            message: "",
            IssuedId: "",
            loading: false,
            authenticated: true,
        };
        this.end = this.end.bind(this);
        this.loadVideo = this.loadVideo.bind(this);
        this.loadNode = this.loadNode.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.authenticate = this.authenticate.bind(this);
        this.confirmNode = this.confirmNode.bind(this);
        this.refer = this.refer.bind(this);
        this.empleador = this.empleador.bind(this);
    }


    end() {
        this.props.close();
    }
    empleador(event) {
        this.props.videoIndex({ 'video': event.target.getAttribute("video-url") });
        this.props.switchMode()
    }

    componentDidUpdate() {
        if (undefined !== this.props.updateNode.id && this.state.node.id !== this.props.updateNode.id && this.props.update === true) {
            this.props.actualNode(this.props.updateNode.id);
            this.setState({ node: this.props.updateNode })

        }
    }
    handleChange(event) {
        var alert = this.props.domelement.querySelector('.alert-info');
        alert.setAttribute("hidden", true);
        // if (event.target.getAttribute("type") === "Email") {
        //     // eslint-disable-next-line
        //     const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        //     var validate = re.test(event.target.value);
        //     this.setState({ Email: event.target.value });
        //     if (!validate) {
        //         this.setState({ message: "No es una dirección de email válida ejemplo: ejemplo@ejemplo.com" });
        //         alert.removeAttribute("hidden");
        //         this.setState({ Email: null });
        //     }

        // }
        // else
        if (event.target.getAttribute("id") === "Id") {
            // eslint-disable-next-line
            const re = /[0-9]$/;
            var validate = re.test(event.target.value);
            if (validate && event.target.value.length === 11) {
                this.setState({ IssuedId: event.target.value });
            } else if (event.target.value.length > 11) {
                event.target.value = this.state.IssuedId;
            } else {
                this.setState({ IssuedId: null });
            }

            if (event.target.value.length < 11) {
                this.setState({ message: "No es una Cédula válida, debe contener 11 digitos" });
                alert.removeAttribute("hidden");
            }


        } else if (event.target.getAttribute("id") === "CellPhone") {
            // eslint-disable-next-line
            const re = /[0-9]$/;
            validate = re.test(event.target.value);
            if (validate & event.target.value.length === 10) {
                this.setState({ CellPhone: event.target.value });
            } else if (event.target.value.length > 10) {
                event.target.value = this.state.CellPhone;
            }

            if (event.target.value.length < 10) {
                this.setState({ message: "El numero celular  debe contener al menos 10 digitos" });
                alert.removeAttribute("hidden");
                this.setState({ CellPhone: null })
            }

        } else {
            // eslint-disable-next-line
            const re = /[A-Za-z ]$/;
            validate = re.test(event.target.value);
            if (!validate) {
                this.setState({ message: "No se admiten numeros o simbolos especiales" });
                alert.removeAttribute("hidden");
                this.setState({ Name: null })
                event.target.value = this.state.Name;
            } else {
                this.setState({ Name: event.target.value });
            }
        }

    }

    async refer(event) {
        if ((this.state.IssuedId !== null && this.state.CellPhone !== null) && this.state.Name !== null && this.state.Name !== undefined && this.state.IssuedId !== undefined && this.state.CellPhone !== undefined && this.state.IssuedId.length <= 11) {
            ReferEvent(this.state.Name, this.state.CellPhone, this.state.IssuedId);
            this.loadNode(event);
            this.setState({ CellPhone: null })
            this.setState({ IssuedId: null })
        } else {
            this.setState({ message: "Debes completar los campos requeridos" });
            var alert = this.props.domelement.querySelector('.alert-info');
            alert.removeAttribute("hidden");
        }
    }
    loadVideo(event) {
        this.props.videoIndex({ 'video': event.target.getAttribute("video-url") });
    }

    loadNode(event) {
        var nodeIndex = event.target.getAttribute("actual-id");
        var buttonIndex = event.target.getAttribute("button-index") - 1;

        ReactGa.event({
            category: "Botones",
            action: nodes[nodeIndex].buttons[buttonIndex].text,
            label: nodes[nodeIndex].buttons[buttonIndex].text, // optional
            //value: 99, // optional, must be a number
            //nonInteraction: true, // optional, true/false
            // transport: "xhr", // optional, beacon/xhr/image
        });
        var alert = this.props.domelement.querySelector('.alert-info');
        alert.setAttribute("hidden", true);
        //security question evaluation, if the button node is not the correct set authenticathed false 
        try {
            if (nodes[nodeIndex].buttons[buttonIndex].correct === false) {
                this.setState({ authenticated: false });
            }
        } catch {

        }

        this.props.videoIndex({ 'video': event.target.getAttribute("video-url") });
        var index = parseInt(event.target.getAttribute("node-id"));
        this.props.actualNode(index);
        this.setState({ node: nodes[index] });
    }

    async confirmNode(event) {
        var nodeIndex = event.target.getAttribute("actual-id");
        var buttonIndex = event.target.getAttribute("button-index") - 1;
        var authenticated = this.state.authenticated;
        try {
            if (nodes[nodeIndex].buttons[buttonIndex].correct === false) {
                this.setState({ authenticated: false });
                authenticated = false;
            }
        } catch {

        }
        // ReactGa.event({
        //     category: "Botones",
        //     action: nodes[nodeIndex].buttons[buttonIndex].text,
        //     label: nodes[nodeIndex].buttons[buttonIndex].text, // optional
        //     //value: 99, // optional, must be a number
        //     //nonInteraction: true, // optional, true/false
        //     // transport: "xhr", // optional, beacon/xhr/image
        // });
        this.setState({ loading: true }); // loading circle
        var props = this.props;
        var index = 23;
        if (authenticated === true) {
            await axios.post(process.env.REACT_APP_API_URL + '/api/createvideo/', { UIssuedId: this.state.IssuedId, Ucell: this.state.CellPhone })
                .then(function (response) {

                    var state = response.data.status;
                    if (state === "complete") {
                        var node = {
                            id: nodes.length,
                            title: '¿Qué quieres hacer?',
                            inputs: [],
                            buttons: [
                                {
                                    type: 'node',
                                    videoUrl: response.data.download,
                                    text: "Confirmar",
                                    nextNodeId: 23,
                                },
                                {
                                    type: 'node',
                                    videoUrl: IntroAfiliado,
                                    text: "Regresar",
                                    correct: 1,
                                    nextNodeId: 4,
                                }

                            ],
                        }
                        nodes.push(node);
                        props.videoIndex({ 'video': TienesUnEstado });
                        index = nodes.length - 1;

                    } else {
                        props.videoIndex({ 'video': event.target.getAttribute("video-url") });
                        index = parseInt(event.target.getAttribute("node-id"));
                    }
                })

                .catch(function (error) {
                    props.videoIndex({ 'video': ErrorSistema });
                    index = 5;
                });
        } else {
            index = 12;
            this.setState({ authenticated: true });
            props.videoIndex({ 'video': RespuestaNoConcuerda });
            await authenticateAttempt(this.state.IssuedId);
        }
        this.setState({ node: nodes[index] });
        this.setState({ loading: false }); // loading circle
        this.setState({ CellPhone: null })
        this.setState({ IssuedId: null })
    }


    async authenticate(event) {

        var props = this.props;
        var index;
        if ((this.state.IssuedId !== null && this.state.CellPhone !== null) && (this.state.IssuedId !== undefined && this.state.CellPhone !== undefined) && this.state.IssuedId.length <= 11) {
            this.setState({ loading: true }); // loading circle
            var content = {
                IssuedId: this.state.IssuedId,
                CellPhone: this.state.CellPhone
            }
            await axios.post(process.env.REACT_APP_API_URL + '/api/user/authenticate/', content)
                .then(function (response) {

                    if (response.data.successed === true) {
                        var titles = {
                            1: "¿Cuál de estos teléfonos reconoces?",
                            2: "¿Cuál es tu fecha de nacimiento?",
                            3: "¿Cuál es o fue tu último empleador?"
                        }
                        var secQuestions = response.data.value;
                        //add new node to the end of array with the two options dates 
                        var x = 0;
                        var questionIsEmpty = false;
                        secQuestions.forEach(question => {
                            var title = titles[question.codPreguntas];
                            x++;
                            var node = {
                                id: nodes.length,
                                title: title,
                                inputs: [],
                                buttons: [],

                            };

                            if (question.respuestas.length === 0) {
                                questionIsEmpty = true;
                            }
                            question.respuestas.forEach(respuesta => {
                                var type = 'node';
                                var videoUrl;
                                var nextNodeId;
                                switch (x) {
                                    case 1:
                                        if (secQuestions[2]?.respuestas?.length ? secQuestions[2].respuestas.length : 0 === 0) {
                                            if (respuesta.esCorrecta === true) {
                                                type = 'confirm';
                                                videoUrl = confirmacion;
                                                nextNodeId = 21;
                                            } else {
                                                type = 'confirm';
                                                videoUrl = RespuestaNoConcuerda;
                                                nextNodeId = 12;
                                            }
                                        } else {
                                            videoUrl = UltimoEmpleador;
                                            nextNodeId = nodes.length + 2;
                                        }
                                        break;
                                    case 2:
                                        videoUrl = NumeroTelefono;
                                        nextNodeId = nodes.length - 1;
                                        break;
                                    case 3:
                                        if (respuesta.esCorrecta === true) {
                                            type = 'confirm';
                                            videoUrl = confirmacion;
                                            nextNodeId = 21;
                                        } else {
                                            type = 'confirm'
                                            videoUrl = RespuestaNoConcuerda;
                                            nextNodeId = 12;
                                        }
                                        break;

                                    default:
                                        break;
                                }

                                var button = {
                                    type: type,
                                    videoUrl: videoUrl,
                                    text: respuesta.valor,
                                    correct: respuesta.esCorrecta,
                                    nextNodeId: nextNodeId,
                                }
                                node.buttons.push(button);

                            });
                            if (questionIsEmpty) {
                                //dont push the node is there is not answer for the questions
                            } else {
                                nodes.push(node);
                            }


                        });

                        props.videoIndex({ 'video': event.target.getAttribute("video-url") });
                        if (secQuestions[2]?.respuestas?.length ? secQuestions[2].respuestas.length : 0 === 0) {
                            index = nodes.length - 1;
                        } else {
                            index = nodes.length - 2;
                        }

                        if (response.data.blocked === true) {
                            props.videoIndex({ 'video': intentosExcedidos });
                            index = 5; //node index for user max attempt
                        }

                    }
                    else {
                        props.videoIndex({ 'video': Noafiliado });
                        index = 22; //node index for not exist user
                    }

                })
                .catch(function (error) {
                    props.videoIndex({ 'video': ErrorSistema });
                    index = 5;
                });

            this.setState({ node: nodes[index] });
            this.setState({ loading: false });
        } else {
            this.setState({ message: "Debes completar los campos requeridos" });
            var alert = this.props.domelement.querySelector('.alert-info');
            alert.removeAttribute("hidden");
        }

    }

    render() {
        var node = this.state.node;     // actual node 
        var buttons = [];               //initialize array to storage buttons elements for the view 
        var inputs = [];                //initialize array to storage inputs elements for the view 
        var x = 1;                      //counter for button options
        let icons =[]

        node.inputs.forEach(input => {
            if (input.type === "text") {
                inputs.push(<TextField autoComplete='false' className="outlined-basic" type={input.content} key={input.text} label={input.text} id={input.content} variant="filled" margin="dense" required onChange={this.handleChange} />);
            }
            if (input.type === "number") {
                inputs.push(<TextField autoComplete='false' pattern="[0-9]*" style={{ borderColor: 'red', borderWidth: '3px' }} keyboardType='numeric' type='tel' id={input.content} className="outlined-basic" key={input.content} label={input.text} variant="filled" margin="dense" required onChange={this.handleChange} />);
            }
        });

        node.buttons.forEach(button => {
            const text = <span className="inside-button"> <span className='num'> {x} </span ><span>{button.text}</span></span>;
            if (button.type === "video") {
                buttons.push(<Button variant="outlined" key={button.text} className="buttons" video-url={button.videoUrl} onClick={this.loadVideo}> {text}</Button>);
            }
            if (button.type === "node") {
                buttons.push(<Button variant="outlined" key={button.text} className="buttons" node-id={button.nextNodeId} video-url={button.videoUrl} onClick={this.loadNode} button-index={x} actual-id={node.id}>{text} </Button>);
            }
            if (button.type === "send") {
                buttons.push(<Button variant="outlined" key={button.text} className="buttons" node-id={button.nextNodeId} video-url={button.videoUrl} onClick={this.authenticate} button-index={x} actual-id={node.id}> {text}</Button>);
            }
            if (button.type === "confirm") {
                buttons.push(<Button variant="outlined" key={button.text} className="buttons" node-id={button.nextNodeId} video-url={button.videoUrl} onClick={this.confirmNode} confirm={button.correct} button-index={x} actual-id={node.id}> {text}</Button>);
            }
            if (button.type === "refer") {
                buttons.push(<Button variant="outlined" key={button.text} className="buttons" node-id={button.nextNodeId} video-url={button.videoUrl} onClick={this.refer} confirm={button.correct} button-index={x} actual-id={node.id}> {text}</Button>);
            }
            if (button.type === "close") {
                buttons.push(<Button variant="outlined" ref={button.text} className="buttons" node-id={button.nextNodeId} video-url={button.videoUrl} onClick={this.end} button-index={x} actual-id={node.id}>{text}</Button>);
            }
            if (button.type === "empleador") {
                buttons.push(<Button variant="outlined" ref={button.text} className="buttons" node-id={button.nextNodeId} video-url={button.videoUrl} onClick={this.empleador} button-index={x} actual-id={node.id}>{text}</Button>);
            }
            if (button.type === "whatsapp") {
                let message='Hola Camila esto es una prueba'
                let shareUrl='https://olivia.crecerlab.com/'
                icons.push(   
                    <WhatsappShareButton  title={message} url={shareUrl}>
                        <WhatsApp className='icon-list' ></WhatsApp>
                    </WhatsappShareButton>)
            }
            if (button.type === "link") {
                buttons.push(<a href={button.url} target='_parent'><Button variant="outlined" key={button.text} className="buttons" node-id={button.nextNodeId} video-url={button.url}  button-index={x} actual-id={node.id}> {text}</Button> </a>);
            }

            x++;
        });

        return (
            <div className="list" hidden>
                <Backdrop
                    sx={{ color: '#fff', zIndex: 100, pointerEvents: "auto" }}
                    open={this.state.loading}
                //onClick={this.play}""
                >
                    <CircularProgress color="inherit" />

                </Backdrop>
                {icons}
                <Alert severity="info" className="alert-info" hidden >{this.state.message}</Alert>
                <h1 className="title" >{node.title}</h1>
                <div className="buttons-list controls-cta">
                    <Stack>
                        {inputs}
                        {buttons}
                    </Stack>
                </div>
            </div>
        )
    }

}

export default ButtonsList;